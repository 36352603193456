
import type { NuxtSvgSpriteSymbol } from './runtime'
type SymbolImport = {
  import: () => Promise<string>
  attributes: Record<string, string>
}
export const SYMBOL_IMPORTS: Record<NuxtSvgSpriteSymbol, SymbolImport> = {
'add-hexagon': { import: () => import('/app/assets/symbols/add-hexagon.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 25","id":"add-hexagon"} },
  'arrow-left': { import: () => import('/app/assets/symbols/arrow-left.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-left"} },
  'arrow-right': { import: () => import('/app/assets/symbols/arrow-right.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-right"} },
  'chevron-down': { import: () => import('/app/assets/symbols/chevron-down.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","id":"chevron-down"} },
  'close': { import: () => import('/app/assets/symbols/close.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 96 960 960","id":"close"} },
  'logo': { import: () => import('/app/assets/symbols/logo.svg?raw').then(v => v.default), attributes: {"id":"logo","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 464 90.28"} },
  'magnifyingglass': { import: () => import('/app/assets/symbols/magnifyingglass.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"magnifyingglass"} },
  'newspaper': { import: () => import('/app/assets/symbols/newspaper.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"newspaper"} },
  'paperplane': { import: () => import('/app/assets/symbols/paperplane.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"paperplane"} },
  'play': { import: () => import('/app/assets/symbols/play.svg?raw').then(v => v.default), attributes: {"viewBox":"44.9 44.9 83.2 83.2","fill":"none","xmlns":"http://www.w3.org/2000/svg","style":"filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.4));","id":"play"} },
}
  